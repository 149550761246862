<script>
import VxAppBar from "@/components/vx/VxAppBar";
import VxAppBarDate from "@/components/vx/VxAppBarDate";
import EmployeeAction from "@/components/employees/EmployeeAction.js";

import { hasRouteDay } from "@/mixins/routerParams";

export default {
  name: "ReportsAppBar",
  components: {
    VxAppBar,
    VxAppBarDate,
    EmployeeAction,
  },
  mixins: [hasRouteDay],
  computed: {
    reportTitle() {
      return true;
    },
    title() {
      return this.$route.meta.title;
    },
    showBack() {
      return this.$route.meta.showBack;
    },
    dateStyle() {
      return this.$route.meta.toolbarDate;
    },
    weekly() {
      return this.dateStyle === "weekly";
    },
    monthly() {
      return this.dateStyle === "monthly";
    },
  },
  methods: {
    handleClickReports() {
      this.$router.push({ name: "reports.setup" });
    },
    handleClickGoals() {
      this.$router.push({ name: "goal.setup" });
    },
  },
};
</script>

<template>
  <VxAppBar :title="title">
    <template #rightMenuItems>
      <EmployeeAction
        v-slot="{ handleAction, isDisabled, listeners }"
        check-manager
      >
        <v-list-item
          ripple
          v-on="listeners"
          @click="handleAction(handleClickReports)"
        >
          <v-list-item-title
            :class="{ 'text--disabled': isDisabled }"
            v-text="'Reports Setup'"
          />
        </v-list-item>
        <v-list-item
          ripple
          v-on="listeners"
          @click="handleAction(handleClickGoals)"
        >
          <v-list-item-title
            :class="{ 'text--disabled': isDisabled }"
            v-text="'Goals Setup'"
          />
        </v-list-item>
      </EmployeeAction>
    </template>
    <template v-slot:title>
      <v-app-bar-nav-icon v-if="showBack" @click="$router.back()">
        <v-icon>$back</v-icon>
      </v-app-bar-nav-icon>
      <VxAppBarDate
        v-if="!!dateStyle"
        v-model="dayDate"
        :weekly="weekly"
        :monthly="monthly"
      />
      <v-spacer></v-spacer>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </template>
  </VxAppBar>
</template>
