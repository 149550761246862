<script>
import VxAppBarMobile from "@/components/vx/VxAppBar/VxAppBarMobile";
import VxAppBarDate from "@/components/vx/VxAppBarDate";
import EmployeeAction from "@/components/employees/EmployeeAction.js";

import { hasRouteDay } from "@/mixins/routerParams";

export default {
  name: "ReportsAppBar",
  components: {
    VxAppBarMobile,
    VxAppBarDate,
    EmployeeAction,
  },
  mixins: [hasRouteDay],
  computed: {
    reportTitle() {
      return this.$route.meta.reportTitle || false;
    },
    toolbarDate() {
      return this.$route.meta.toolbarDate || false;
    },
    title() {
      return this.reportTitle || this.$route.meta.title;
    },
    dateStyle() {
      return this.$route.meta.toolbarDate || "daily";
    },
    weekly() {
      return this.dateStyle === "weekly";
    },
    monthly() {
      return this.dateStyle === "monthly";
    },
  },
  methods: {
    handleClickReports() {
      this.$router.push({ name: "reports.setup" });
    },
    handleClickGoals() {
      this.$router.push({ name: "goal.setup" });
    },
  },
};
</script>

<template>
  <VxAppBarMobile :title="title">
    <template #rightMenuItems>
      <EmployeeAction
        v-slot="{ handleAction, isDisabled, listeners }"
        check-manager
      >
        <v-list-item
          ripple
          v-on="listeners"
          @click="handleAction(handleClickReports)"
        >
          <v-list-item-title
            :class="{ 'text--disabled': isDisabled }"
            v-text="'Reports Setup'"
          />
        </v-list-item>
        <v-list-item
          ripple
          v-on="listeners"
          @click="handleAction(handleClickGoals)"
        >
          <v-list-item-title
            :class="{ 'text--disabled': isDisabled }"
            v-text="'Goals Setup'"
          />
        </v-list-item>
      </EmployeeAction>
    </template>
    <template v-if="reportTitle" v-slot:navicon>
      <v-app-bar-nav-icon @click="$router.back()">
        <v-icon>$back</v-icon>
      </v-app-bar-nav-icon>
    </template>
    <template v-if="toolbarDate" v-slot:extension>
      <v-spacer></v-spacer>
      <VxAppBarDate v-model="dayDate" :weekly="weekly" :monthly="monthly" />
      <v-spacer></v-spacer>
    </template>
  </VxAppBarMobile>
</template>
